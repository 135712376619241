import React from 'react'
import {Link} from 'gatsby'

const BlogSidebar = () => {
    return (
        <div className="widget-area">
            <div className="widget widget_search">
                <h3 className="widget-title">Search</h3>

                <form className="search-form">
                    <label>
                        <input type="search" className="search-field" placeholder="Search..." />
                    </label>
                    <button type="submit">
                        <i className="bx bx-search-alt"></i>
                    </button>
                </form>
            </div>

            <div className="widget widget_tracer_posts_thumb">
                <h3 className="widget-title">Popular Posts</h3>

                <article className="item">
                    <Link to="/blog-details" className="thumb">
                        <span className="fullimage cover bg1" role="img"></span>
                    </Link>
                    <div className="info">
                        <span>June 10, 2021</span>
                        <h4 className="title usmall">
                            <Link to="/blog-details">
                                Macs and Small Business
                            </Link>
                        </h4>
                    </div>

                    <div className="clear"></div>
                </article>

                <article className="item">
                    <Link to="/blog-details" className="thumb">
                        <span className="fullimage cover bg2" role="img"></span>
                    </Link>
                    <div className="info">
                        <span>June 21, 2021</span>
                        <h4 className="title usmall">
                            <Link to="/blog-details">
                                Macs and Data Processing
                            </Link>
                        </h4>
                    </div>

                    <div className="clear"></div>
                </article>

                <article className="item">
                    <Link to="/blog-details" className="thumb">
                        <span className="fullimage cover bg3" role="img"></span>
                    </Link>
                    <div className="info">
                        <span>June 30, 2022</span>
                        <h4 className="title usmall">
                            <Link to="/blog-details">
                                How to speed my Mac Up
                            </Link>
                        </h4>
                    </div>

                    <div className="clear"></div>
                </article>
            </div>

            <div className="widget widget_categories">
                <h3 className="widget-title">Categories</h3>

                <ul>
                    <li>
                        <Link to="/blog">
                            Design <span className="post-count">(03)</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog">
                            Lifestyle <span className="post-count">(05)</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog">
                            Projects <span className="post-count">(10)</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog">
                            Device <span className="post-count">(08)</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog">
                            Tips <span className="post-count">(01)</span>
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="widget widget_tag_cloud">
                <h3 className="widget-title">Popular Tags</h3>

                <div className="tagcloud">
                    <Link to="/blog">
                        Software showcase <span className="tag-link-count">(3)</span>
                    </Link>
                    <Link to="/blog">
                        Design <span className="tag-link-count">(3)</span>
                    </Link>
                    <Link to="/blog">
                        Projects <span className="tag-link-count">(2)</span>
                    </Link>
                    <Link to="/blog">
                        Buying <span className="tag-link-count">(2)</span>
                    </Link>
                    <Link to="/blog">
                        Mac Travel <span className="tag-link-count">(1)</span>
                    </Link>
                    <Link to="/blog">
                        Smart Gear <span className="tag-link-count">(1)</span>
                    </Link>
                    <Link to="/blog">
                        Small Business <span className="tag-link-count">(1)</span>
                    </Link>
                    <Link to="/blog">
                        Tips <span className="tag-link-count">(2)</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default BlogSidebar